import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import connectToOdysseal from "../utils/connectToOdysseal";
import AppCircularProgress from "../components/AppCircularProgress";

const ref = {
  odysseal: false,
};

const RedirectSSO = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    switch (params.sso) {
      case "odysseal":
        if (ref.odysseal) break;

        ref.odysseal = true;
        const search = new URLSearchParams(document.location.search);

        connectToOdysseal(search.get("ref"))
          .catch(() => history.push("/accueil"))
          .finally(() => {
            ref.odysseal = false;
          });
        break;
    }
  }, [location]);

  return <AppCircularProgress />;
};

export default RedirectSSO;
